let ENDPOINT = process.env.GATSBY_API_URL_MKT

let ENDPOINTWEB = process.env.GATSBY_API_URL_WEB
let ENDPOINTIMG = process.env.GATSBY_API_URL_IMG

export const amplifyconfig = {
  Auth: {
    // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
    identityPoolId: `us-east-1:${process.env.COGNITO_S3_IDENTITY}`,

    // REQUIRED - Amazon Cognito Region
    region: "us-east-1",

    // OPTIONAL - Amazon Cognito User Pool ID
    userPoolId: process.env.COGNITO_POOL_ID,

    userPoolWebClientId: process.env.COGNITO_APPCLIENT_ID,
  },
  API: {
    endpoints: [
      {
        name: "Storylava",
        endpoint: ENDPOINT,
      },
      {
        name: "Storylavaweb",
        endpoint: ENDPOINTWEB,
      },
      {
        name: "Storylavaimg",
        endpoint: ENDPOINTIMG,
      },
    ],
  },
}
